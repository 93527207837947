const PAGE_MAIN = '/';
const PAGE_TOOLS = '/tools';
const PAGE_SITEMAP = '/sitemap';

const PAGE_TOOLS_IMAGE_GENERATE_IMAGE = '/tools/image/generate-image';
const PAGE_TOOLS_IMAGE_GENERATE_IMAGE_AI_1 = '/tools/image/generate-image/ai-1';
const PAGE_TOOLS_IMAGE_GENERATE_IMAGE_AI_2 = '/tools/image/generate-image/ai-2';

const PAGE_BLOG_AI_FOR_IMAGE_GENERATION = '/blog/ai-for-image-generation';
const PAGE_BLOG_FREE_IMAGE_GENERATION = '/blog/free-image-generation';
const PAGE_BLOG_IMAGE_GENERATION = '/blog/image-generation';
const PAGE_BLOG_CREATE_IMAGE = '/blog/create-image';

const PAGE_TOOLS_IMAGE_UPSCALER_IMAGE = '/tools/image/upscaler-image';
const PAGE_TOOLS_IMAGE_UPSCALER_IMAGE_AI_1 = '/tools/image/upscaler-image/ai-1';

const PAGES_TOOLS = {
  PAGE_TOOLS_IMAGE_GENERATE_IMAGE,
  PAGE_TOOLS_IMAGE_GENERATE_IMAGE_AI_1,
  PAGE_TOOLS_IMAGE_GENERATE_IMAGE_AI_2,

  PAGE_TOOLS_IMAGE_UPSCALER_IMAGE,
  PAGE_TOOLS_IMAGE_UPSCALER_IMAGE_AI_1
};

const PAGES_BLOG = {
  PAGE_BLOG_AI_FOR_IMAGE_GENERATION,
  PAGE_BLOG_FREE_IMAGE_GENERATION,
  PAGE_BLOG_IMAGE_GENERATION,
  PAGE_BLOG_CREATE_IMAGE
};

const PAGES = {
  PAGE_MAIN,
  PAGE_TOOLS,
  PAGE_SITEMAP,
  PAGES_TOOLS,
  PAGES_BLOG
};

module.exports = {
  PAGE_MAIN,
  PAGE_TOOLS,
  PAGE_SITEMAP,

  PAGES_TOOLS,
  PAGES_BLOG,
  PAGES
};
